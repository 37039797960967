import { useState, useImperativeHandle, forwardRef, useEffect } from 'react';
import { getAssistantByAssistantId } from '@/app/api/action';
import { FaTimes, FaCheck } from 'react-icons/fa';
import { assistant } from '@/Models/userModel';

interface EditAssistantFormProps {
    initialName?: string;
    initialInstructions?: string;
    updateDetails: (details: { name: string; instructions: string; files: any; suggestions: string[] }) => void;
    assistantId?: string
}

interface EditAssistantFormHandle {
    submitForm: () => void;
}
  
const EditAssistantForm = forwardRef<EditAssistantFormHandle, EditAssistantFormProps>(({ initialName = '', initialInstructions = '',assistantId, updateDetails }, ref) => {
    const [name, setName] = useState(initialName);
    const [instructions, setInstructions] = useState(initialInstructions);
    const [files, setFiles] = useState([]);
    const [words, setWords] = useState(''); // Words entered by the user
    const [suggestions, setWordArray] = useState([]); 
    const [assistantData, setAssistantData] = useState<assistant>(null);

    // Use effect to update the form fields if the initial values change (i.e., when opening the modal for editing)
    useEffect(() => {
        setName(initialName);
        setInstructions(initialInstructions);
    }, [initialName, initialInstructions]);

    // Function to pass updated details to parent
    const updateParent = () => {
        return { name: name, instructions: instructions, files: files, suggestions: suggestions};
    };
    useEffect(() => {
        if(assistantId != null) {
            getAssistantDataById();
        }
    }, [assistantId])
    // Expose the submitForm method to the parent using ref
    useImperativeHandle(ref, () => ({
        submitForm: updateParent
    }));
    const getAssistantDataById = async () => {
        let assistantData = await getAssistantByAssistantId(assistantId);
        setAssistantData(assistantData);
        let assistantSuggestions = assistantData.suggestions.filter(suggestion => suggestion.parentName === '').map(sugg => sugg.name);
        setName(assistantData.name);
        setInstructions(assistantData.instructions);
        setWordArray(assistantSuggestions);
        let words = assistantSuggestions.join(',');
        setWords(words+',');
        setFiles(assistantData.fileDetails);
    }
    const handleFileChange = (e) => {
        const selectedFiles = Array.from(e.target.files);
        let prevFiles = files;
        let array = [...prevFiles, ...selectedFiles]
        const uniqueArray = array.reduce((accumulator, current) => {
            const x = accumulator.find(item => item.name === current.name);
            if (!x) {
                return accumulator.concat([current]);
            } else {
                return accumulator;
            }
        }, []);
        setFiles(uniqueArray);
    };
  
    const handleRemoveFile = (index) => {
    setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index)); // Remove file by index
    };
    const handleWordChange = (e) => {
        setWords(e.target.value);
        splitWords(e.target.value);
    };

    const splitWords = (inputWords) => {
        let splitArray = inputWords.split(',').filter((word) => word.trim() !== '');
        splitArray = splitArray.map(word => word.trim());
        setWordArray(splitArray);
    };
    return (
        <div>
            <div className="bg-[#FEF3C7] border-l-4 border-[#F59E0B] text-[#92400E] p-3 mb-4 mt-0 rounded">
                <p className="font-medium text-sm">
                Changing the Suggestions would impact the sub-tags added to each suggestion.
                </p>
            </div>
            <form className="space-y-4">
                <div className="flex flex-col">
                    <label className="mb-1 font-medium text-gray-700">Assistant Name</label>
                    <input
                        type="text"
                        className="w-full px-3 py-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        required
                    />
                </div>

                <div className="flex flex-col">
                    <label className="mb-1 font-medium text-gray-700">Instructions</label>
                    <textarea
                        className="w-full px-3 py-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500"
                        value={instructions}
                        onChange={(e) => setInstructions(e.target.value)}
                        required
                    />
                </div>
                
                <div className="flex flex-col">
                <div className='flex items-center'>
                    <input
                        type="file"
                        id="fileInput"
                        className="hidden"
                        onChange={handleFileChange}
                        multiple // Allows multiple file uploads
                        required
                    />
                    <label htmlFor="fileInput" className="flex items-center cursor-pointer bg-[#667eea] text-white font-bold py-2 px-4 rounded-lg shadow-sm transform hover:scale-105">
                        <span className="mr-2 text-lg">+</span> Upload Files
                    </label>
                    <div className='ms-4'>{files.length} files choosen</div>
                </div>
                {files.length > 0 && (
                    <div className="mt-4 flex flex-wrap gap-1">
                    {files.map((fileData, index) => (
                        <div
                        key={index}
                        className="flex items-center justify-between p-3 h-fit border rounded-lg shadow-sm"
                        >
                        <div className="flex items-center space-x-4">
                            {/* {renderFileIcon(fileData.file)} */}
                            <span className="text-sm font-medium">{fileData.name}</span>
                        </div>
                        <button
                            onClick={() => handleRemoveFile(index)}
                            className="ms-4"
                            >
                            <FaTimes />
                        </button>
                        </div>
                    ))}
                    </div>
                )}
                </div>
                <div>
                    <label className="block mb-1 font-medium text-gray-700">Enter the Suggestions</label>
                    <textarea
                    className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500"
                    value={words}
                    onChange={handleWordChange}
                    placeholder="Enter the suggestions separated by comma"
                    required
                    />
                </div>

                <div className='flex flex-wrap'>
                    {suggestions.map((word, index) => (
                        <div className="border border-[#e8eaee] rounded-md text-black w-max p-1 m-1" key={index}>{word}</div>
                    ))}
                </div>
            </form>
        </div>
    );
});

export default EditAssistantForm;