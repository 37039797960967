// components/SlidingPanel.js
import React from 'react';

export function SlidingPanel({ isOpen, onClose, headerTitle, children, onAction, actionLabel }) {
  return (
    <div className={`fixed inset-0 z-50 flex justify-end transition-transform transform ${isOpen ? 'translate-x-0' : 'translate-x-full'}`}>
      {/* Overlay */}
      <div
        className={`fixed inset-0 bg-[#1F2937] bg-opacity-50 transition-opacity duration-300 ease-in-out ${
          isOpen ? 'opacity-100' : 'opacity-0 pointer-events-none '
        }`}
        onClick={onClose}
      ></div>

      {/* Sliding Panel (right-aligned with header, content, and footer) */}
      <div className="relative w-full md:w-1/2 bg-white h-full shadow-lg flex flex-col">
        {/* Header */}
        <div className="flex items-center justify-between p-4 border-b bg-gray-100">
          <h2 className="text-lg font-semibold">{headerTitle || 'Panel Header'}</h2>
          <button
            onClick={onClose}
            className="text-gray-500 hover:text-gray-700 focus:outline-none text-[20px] font-bold"
          >
            &times;
          </button>
        </div>

        {/* Main content area (scrollable) */}
        <div className="flex-1 overflow-y-auto p-6">
          {children}
        </div>

        {/* Footer */}
        <div className="p-4 border-t bg-gray-100">
          <button
            onClick={onClose}
            className="px-4 py-2 bg-[red] mx-2 text-white rounded-md shadow hover:bg-red-600 focus:outline-none"
          >
            Close
          </button>
          <button
            onClick={onAction} // Bind the passed action handler
            className="px-4 py-2 bg-[#1f5dc1] mx-2 text-white rounded-md shadow hover:bg-indigo-700 focus:outline-none"
          >
            {actionLabel || 'Submit'}
          </button>
        </div>
      </div>
    </div>
  );
}
