'use client';
import React, { useState, useEffect, useRef,Dispatch,SetStateAction } from "react";
import { useRouter } from "next/navigation";
import { useSession, signOut } from "next-auth/react";
import { SlidingPanel } from "@/components/ui/modal";
import CreateAssistantForm from "@/components/CreateAssistantForm";
import EditAssistantForm from "@/components/EditAssistantForm";
import AssistantCard from "@/components/ui/assitantCard";
import { PlusCircledIcon } from "@radix-ui/react-icons";
import toast from "react-hot-toast";
import Loading from "@/components/ui/loading";
import DynamicImage from "@/components/ui/DynamicImage";
import { House, UserPlus, LogOut, UserPen } from 'lucide-react';
import { Button } from "@/components/ui/button";
import axios from "axios";
import Link from "next/link";
import OpenAI from 'openai';

function Home(): React.ReactElement {
    const [assistants, setAssistants] = useState<Assistant[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [details, setDetails] = useState({});
    const [editAssistantId, setEditAssistantId] = useState<string | null>(null);
    const [loginLoading, setLoginLoading] = useState(false); // Loading for login
    const [formLoading, setFormLoading] = useState(false); // New loading state for form submission
    const [dataReady, setDataReady] = useState(false);
    const [tone, setTone] = useState('friendly');
    const [style, setStyle] = useState('detailed');
    const [formality, setFormality] = useState('informal');
    const [empathy, setEmpathy] = useState('low');
    const [expertise, setExpertise] = useState('general');
    const [openai, setOpenai] = useState<any>(null);
    const { status } = useSession();
    const router = useRouter();
    const formRef = useRef(null);
    const editRef = useRef(null);

    useEffect(()=>{
        let getOpenai = new OpenAI({ apiKey: process.env.NEXT_PUBLIC_OPENAI_API_KEY, dangerouslyAllowBrowser: true })
        setOpenai(getOpenai);
    },[]);

    const fetchData = async () => {
        try {
            const response = await fetch('/api',{ cache: "no-store" });
            const data = await response.json();
            setAssistants(data.assistantData);
            setDataReady(true);
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };

    const createAssistant = async () => {
        if (formRef.current) {
            setFormLoading(true); // Set form loading when creating assistant
            try {
                await formRef.current.submitForm();
                fetchData(); // Refetch to get the updated data
            } catch (error) {
                console.error("Error creating assistant:", error);
                toast.error("Failed to create assistant. Please try again.");
            } finally {
                setFormLoading(false); // Turn off loading after submission
                setIsCreateModalOpen(false);
            }
        }
    };
    const generateInstruction = (myInstructions) => {
        return `${myInstructions}. 
        - Your tone is ${tone}. 
        - You provide ${style} explanations and summarize trends in the data. 
        - Your formality level is ${formality}, ensuring professionalism in all communications. 
        - You express ${empathy === 'high' ? 'high levels of empathy and understanding' : 'minimal empathy and focus on data-driven responses'}.`;
    };
    const updateAssistant = async (data) => {
        let fileIds = []
        const fileDetails = []
        if(data.files.length>0){
            for await (const file of data.files) {
            if(file.id!=null&&file.id!=undefined){
                    fileIds.push(file.id)
                    fileDetails.push(file)
            }else{
                    let saveFile = await openai.files.create({
                        file: file,
                        purpose: "assistants",
                    })
                    fileIds.push(saveFile.id)
              fileDetails.push({id:saveFile.id,name:file.name})
                }
            }
        }
        let bodyTools = {
            'file_search': {
                vector_store_ids: []
            }
        };
        const batch = await openai.beta.vectorStores.create({
            name: `Vector Store - ${data.name}`,
            file_ids: fileIds
        });

        bodyTools['file_search'] = {
            vector_store_ids: [batch.id]
        };
        let model = "gpt-4o";
        let finalInstruction = generateInstruction(data.instructions);
        await openai.beta.assistants.update(data.id,{
            name: data.name,
            instructions: finalInstruction,
            model:model,
            tools: [{ type: "file_search" }],
            tool_resources: {
                ...bodyTools
            }
        })

        await axios.patch('/api/assistant', { id: data.id, name:data.name,instructions:data.instructions, fileDetails:fileDetails, suggestions: data.suggestions });
        toast.success('Assistant Updated!');
    }
    const editAssistant = async () => {
        if (editRef.current && editAssistantId) {
            setFormLoading(true); // Set form loading when editing assistant
            try {
                let assistantDetails = await editRef.current.submitForm(); // Ensure details are updated
                await updateAssistant({ id: editAssistantId, ...assistantDetails });
                setIsEditModalOpen(false); // Close the modal after successful editing
                fetchData(); // Refetch to get the updated data
            } catch (error) {
                console.error("Error editing assistant:", error);
                toast.error("Failed to edit the assistant. Please try again.");
            } finally {
                setFormLoading(false); // Turn off loading after submission
                setIsEditModalOpen(false);
            }
        }
    };

    const openEditModal = (id: string) => {
        setEditAssistantId(id);
        setIsEditModalOpen(true);
    };

    const closeModal = (func: Dispatch<SetStateAction<boolean>>) => () => func(false);

    const handleDeleteAssistant = async (assistantId: string) => {
        await fetch(`/api/delete/?assistantId=${assistantId}`,{ cache: "no-store" });
        setAssistants(prev => prev.filter(assistant => assistant.id !== assistantId));
    };

    const shareLinkHandler = (assistantId: string) => {
        navigator.clipboard.writeText(window.location.host + '/embed/' + assistantId);
        toast.success('Link copied');
    };

    const handleLogout = async () => {
        try {
          await signOut({ callbackUrl: '/login', redirect: true });
          toast.success('Logged out successfully!');
        } catch (error) {
          toast.error('Logout failed. Please try again.');
        }
      };

    useEffect(() => {
        fetchData();
    }, []);

    useEffect( () => {
        if (status === "unauthenticated") {
            router.push("/login");
        }
    }, [status])
    return (
        <div className="flex w-[100%] flex-col">
            <div className="flex w-[100%] h-[88vh] flex-row">
                {/* Sidebar Navigation */}
                <div className="w-[15%] md:w-[5%] h-screen md:h-[100vh] overflow-auto flex flex-col justify-start items-center py-6 gap-12 bg-[#000] text-white">
                    <Button size="icon" variant="customized" className="cursor-not-allowed aspect-square rounded-md w-[100%]">
                        <House />
                    </Button>
                    <Button variant="customized" size="icon" className="rounded-md w-[100%] aspect-square" onClick={() => setIsCreateModalOpen(true)}>
                        <UserPlus />
                    </Button>
                    <Button variant="customized" size="icon" className="rounded-md w-[100%] aspect-square" onClick={() => router.push('/settings')}>
                        <UserPen />
                    </Button>
                    <Button
                        variant="customized"
                        size="icon"
                        className="rounded-md w-[100%] aspect-square"
                        onClick={handleLogout}
                    >
                        <LogOut />
                    </Button>
                </div>

                {/* Main Content */}
                <main className="flex flex-col h-[100vh] w-[95%] p-[1rem] relative">
                    {(status === "loading" || loading || loginLoading || formLoading) && (
                        <div className="fixed inset-0 z-50 flex justify-center items-center">
                            <Loading /> {/* Loading spinner */}
                        </div>
                    )}

                    {!loading && !loginLoading && !formLoading && (
                        <>
                            <div className="flex flex-row justify-between items-center h-[12vh]">
                                <Link href="/" className="flex items-center gap-2" prefetch={false}>
                                    <DynamicImage src="/logo.svg" alt="logo" width={150} height={75} className="object-cover h-[75px]" />
                                </Link>
                                <button onClick={() => setIsCreateModalOpen(true)} className="mr-4 h-12 bg-gradient-to-r from-[#85CC1F] to-[#067A3B] text-white rounded-lg flex items-center gap-3 py-2 px-4">
                                    <PlusCircledIcon height={20} width={20} />
                                    Create New Assistant Bot
                                </button>
                            </div>

                            <h3 className="mt-4 w-full text-black px-6 py-2 text-[23px] font-raleway-bold">Assistants you have created</h3>

                            <div className="w-full flex flex-col gap-5 text-gray-800">
                                {assistants.length === 0 ? (
                                    <div className="flex flex-col items-center bg-[#F7FAFF] justify-center h-64 border-2 border-graylight rounded-lg text-center">
                                        <img src="/danger.svg" alt="icon" className="w-30 h-30 mb-4" />
                                        You have no Bots created as of now.
                                        <br />
                                        Create your desired bots.
                                    </div>
                                ) : (
                                    <div className="flex flex-wrap gap-4 justify-center items-center sm:justify-start sm:items-start">
                                        {assistants.map((assistant) => (
                                            <div key={assistant.id} className="w-[60%] md:w-[24%] flex">
                                                <AssistantCard
                                                    id={assistant.id}
                                                    name={assistant.name}
                                                    openModal={openEditModal}
                                                    deleteHandler={() => handleDeleteAssistant(assistant.id)}
                                                    shareLinkHandler={() => shareLinkHandler(assistant.id)}
                                                />
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>

                            <SlidingPanel isOpen={isCreateModalOpen} onClose={() => setIsCreateModalOpen(false)} headerTitle="Create New Assistant" onAction={createAssistant} actionLabel="Create">
                                <CreateAssistantForm ref={formRef} />
                            </SlidingPanel>

                            <SlidingPanel isOpen={isEditModalOpen} onClose={() => setIsEditModalOpen(false)} headerTitle="Edit Assistant" onAction={editAssistant} actionLabel="Edit">
                                <EditAssistantForm ref={editRef} updateDetails={setDetails} initialName={""} initialInstructions={""} assistantId={editAssistantId} />
                            </SlidingPanel>
                        </>
                    )}
                </main>
            </div>
        </div>
    );
}

export default Home;
