import { Pencil2Icon, Link1Icon, TrashIcon } from '@radix-ui/react-icons';
import React from 'react';
import { useRouter } from "next/navigation";
import toast from 'react-hot-toast'; // To show success message after copying the link
interface AssistantCardProps {
  id: string;
  name: string;
  openModal: (id: string) => void;  // Function to open the edit modal
  shareLinkHandler: (id: string) => void;  // Function to share assistant link
  deleteHandler: (id: string) => void;  // Function to delete the assistant
}

const AssistantCard: React.FC<AssistantCardProps> = ({ id, name, openModal, shareLinkHandler, deleteHandler }) => {
  const router = useRouter();
  const handleEdit = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation(); // Prevent event from bubbling up
    console.log('Edit button clicked, ID:', id);
    openModal(id); // Open the modal and pass the assistant ID
  };

  const handleNameClick = () => {
    router.push(`/create/${id}`); // Navigate to the create page for the specific assistant
  };

  // Handle the clipboard copy operation in the shareLinkHandler
  const handleShareLink = async (e: React.MouseEvent) => {
    
    e.stopPropagation(); // Prevent event from bubbling up

    try {
      const link = `${window.location.origin}/embed/${id}`; // Generate the assistant link
      await navigator.clipboard.writeText(link); // Write the link to the clipboard
      toast.success('Link copied to clipboard'); // Show success message
    } catch (error) {
      toast.error('Failed to copy link'); // Show error message if copy fails
      // console.error('Error copying link:', error);
    }
  };

  return (
    <div className="flex-1 border-[#2874EB] rounded-lg text-[#2874EB] relative m-4 flex h-16 w-72 items-center justify-around rounded-2xl font-medium border-2 py-2 px-4 text-start text-[15px] cursor-pointer">
      <span onClick={handleNameClick}>{name}</span>
      <div className="flex items-center space-x-2">
        
        {/* Edit Button */}
        <div onClick={handleEdit} className="z-10 relative hover:bg-[#2874EB] hover:text-[white] p-2 rounded-lg group cursor-pointer">
  <Pencil2Icon height={20} width={20} className='text-[#2874EB] group-hover:text-[white]' />
</div>

        {/* Share Button */}
        <div
          onClick={handleShareLink} // Use the new share link handler with clipboard copy
          className="z-50 relative hover:bg-[#2874EB] p-2 rounded-lg group cursor-pointer">
          <Link1Icon height={20} width={20} className='text-[#2874EB] group-hover:text-[white]' />
        </div>
      </div>

      {/* Delete Button */}
      <div
        onClick={(e) => {
          e.stopPropagation(); // Prevent event from bubbling up
          deleteHandler(id); // Pass the assistant id when deleting
        }}
        className="z-10 absolute p-2 -right-4 -top-4 bg-[#2874EB] hover:bg-[#f36c6a] rounded-[50%] cursor-pointer"
      >
        <TrashIcon height={20} width={20} color="white" />
      </div>
    </div>
  );
};

export default AssistantCard;

