import { useState, useImperativeHandle, forwardRef, useEffect } from 'react';
import { useRouter } from "next/navigation";
import OpenAI from 'openai';
import { useSearchParams } from 'next/navigation';
import { FaTimes, FaCheck } from 'react-icons/fa';
import axios from 'axios';


const CreateAssistantForm = forwardRef((props, ref) => {
    const [name, setName] = useState('');
    const [instructions, setInstructions] = useState('');
    const [files, setFiles] = useState([]);
    const [tone, setTone] = useState('friendly');
    const [style, setStyle] = useState('detailed');
    const [formality, setFormality] = useState('informal');
    const [empathy, setEmpathy] = useState('low');
    const [expertise, setExpertise] = useState('general');
    const [openai, setOpenai] = useState<any>(null);
    const [assistant, setAssistant] = useState<any>(null);
    const searchParams = useSearchParams();
    const router = useRouter();
    const [words, setWords] = useState(''); // Words entered by the user
    const [wordArray, setWordArray] = useState([]); 
  
  
    const behaviorOptions = {
      tone,
      style,
      formality,
      empathy,
      expertise,
    };

    const handleWordChange = (e) => {
        setWords(e.target.value);
        splitWords(e.target.value);
    };

    const splitWords = (inputWords) => {
      let splitArray = inputWords.split(',').filter((word) => word.trim() !== '');
      splitArray = splitArray.map(word => word.trim());
      setWordArray(splitArray);
    };
    let bodyTools = {
        'file_search': {
          vector_store_ids: []
        }
    };
  
    useEffect(()=>{
        let getOpenai = new OpenAI({ apiKey: process.env.NEXT_PUBLIC_OPENAI_API_KEY, dangerouslyAllowBrowser: true })
        setOpenai(getOpenai);
    },[]);

    const generateInstruction = () => {
        return `${instructions}. 
        - Your tone is ${tone}. 
        - You provide ${style} explanations and summarize trends in the data. 
        - Your formality level is ${formality}, ensuring professionalism in all communications. 
        - You express ${empathy === 'high' ? 'high levels of empathy and understanding' : 'minimal empathy and focus on data-driven responses'}.`;
    };
    
    const createAssistant = async(): Promise<void> => {
      if(name!=""&&instructions!=""){
        let fileIds = []
        let fileDetails = []
        if(files.length>0){
          for await (const file of files) {
            if(file.id!=null&&file.id!=undefined){
              fileIds.push(file.id)
              fileDetails.push(file)
            }else{
              
              let saveFile = await openai.files.create({
              file: file,
              purpose: "assistants",
              })
              fileIds.push(saveFile.id)
              fileDetails.push({id:saveFile.id,name:file.name})
            }
          }
        }
        let suggestionsArray = wordArray.map(word => {
          return {
            name : word,
            parentName: ''
          }
        })
        const batch = await openai.beta.vectorStores.create({
            name: `Vector Store - ${name}`,
            file_ids: fileIds
          });
    
          bodyTools['file_search'] = {
            vector_store_ids: [batch.id]
        };

        let tools = []
        let model = "gpt-4o";
        let finalInstruction = generateInstruction();
        let getAssistant;
        if(assistant==null){
          getAssistant = await openai.beta.assistants.create({
            name: name,
            instructions: finalInstruction,
            model:model,
            tools: [{ type: "file_search" }],
            tool_resources: {
               ...bodyTools
              }
          })
        }else{
          console.log("updating")
          getAssistant = await openai.beta.assistants.update(assistant,{
            name: name,
            instructions: instructions,
            model:model,
            behaviorOptions: behaviorOptions,
            tools: tools,
            // file_ids: fileIds
          })
        }
        setAssistant(getAssistant.id)
        setFiles(fileDetails)
         
        await axios.post('/api/assistant', { id: getAssistant.id, name:name,instructions:instructions,model:model,tools:tools,behaviorOptions: behaviorOptions, fileDetails:fileDetails, suggestionsArray:suggestionsArray, batch });

        router.push('/create/'+getAssistant.id)
        
      }else{
        alert("Add you assistant's name and instructions!")
      }
    }
  
    const handleFileChange = (e) => {
      const files = Array.from(e.target.files)
      setFiles(files);
    };

    const handleRemoveFile = (index) => {
      setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index)); // Remove file by index
    };
    
    useImperativeHandle(ref, () => ({
        submitForm: createAssistant,
    }));
    return (
      <form className="space-y-4">
        <div className="flex flex-col">
          <label className="mb-1 font-medium text-gray-700">Assistant Name</label>
          <input
            type="text"
            className="w-full px-3 py-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
        </div>
  
        <div className="flex flex-col">
          <label className="mb-1 font-medium text-gray-700">Instructions</label>
          <textarea
            className="w-full px-3 py-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500"
            value={instructions}
            onChange={(e) => setInstructions(e.target.value)}
            required
          />
        </div>
        <div className="flex flex-col">
        <div className='flex items-center'>
          <input
              type="file"
              id="fileInput"
              className="hidden"
              onChange={handleFileChange}
              multiple // Allows multiple file uploads
              required
          />
          <label htmlFor="fileInput" className="flex items-center cursor-pointer bg-[#667eea] text-white font-bold py-2 px-4 rounded-lg shadow-sm transform hover:scale-105">
              <span className="mr-2 text-lg">+</span> Upload Files
          </label>
          <div className='ms-4'>{files.length} files choosen</div>
        </div>
          {files.length > 0 && (
            <div className="mt-4 flex flex-wrap gap-1">
              {files.map((fileData, index) => (
                <div
                  key={index}
                  className="flex items-center justify-between p-3 h-fit border rounded-lg shadow-sm"
                >
                  <div className="flex items-center space-x-4">
                    {/* {renderFileIcon(fileData.file)} */}
                    <span className="text-sm font-medium">{fileData.name}</span>
                  </div>
                  <button
                      onClick={() => handleRemoveFile(index)}
                      className="ms-4"
                    >
                      <FaTimes />
                  </button>
                </div>
              ))}
            </div>
          )}
        </div>
        <div>
            <label className="block mb-1 font-medium text-gray-700">Enter the Suggestions</label>
            <textarea
            className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500"
            value={words}
            onChange={handleWordChange}
            required
            placeholder="Enter the suggestions separated by comma"
            />
        </div>

        <div className='flex flex-wrap'>
            {wordArray.map((word, index) => (
                <div className="border border-[#e8eaee] rounded-md text-black w-max p-1 m-1" key={index}>{word}</div>
            ))}
        </div>
        <div className="flex flex-col">
          <label className="mb-1 font-medium text-gray-700">Tone</label>
          <select
            className="w-full px-3 py-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500"
            value={tone}
            onChange={(e) => setTone(e.target.value)}
          >
            <option value="friendly">Friendly</option>
            <option value="professional">Professional</option>
            <option value="casual">Casual</option>
          </select>
        </div>
  
        {/* <div className="flex flex-col">
          <label className="mb-1 font-medium text-gray-700">Style</label>
          <select
            className="w-full px-3 py-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500"
            value={style}
            onChange={(e) => setStyle(e.target.value)}
          >
            <option value="concise">Concise</option>
            <option value="detailed">Detailed</option>
            <option value="conversational">Conversational</option>
          </select>
        </div> */}
  
        <div className="flex flex-col">
          <label className="mb-1 font-medium text-gray-700">Formality</label>
          <select
            className="w-full px-3 py-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500"
            value={formality}
            onChange={(e) => setFormality(e.target.value)}
          >
            <option value="informal">Informal</option>
            <option value="formal">Formal</option>
          </select>
        </div>
  
        {/* <div className="flex flex-col">
          <label className="mb-1 font-medium text-gray-700">Empathy Level</label>
          <select
            className="w-full px-3 py-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500"
            value={empathy}
            onChange={(e) => setEmpathy(e.target.value)}
          >
            <option value="high">High</option>
            <option value="low">Low</option>
          </select>
        </div> */}
  
        {/* <div className="flex flex-col">
          <label className="mb-1 font-medium text-gray-700">Expertise</label>
          <select
            className="w-full px-3 py-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500"
            value={expertise}
            onChange={(e) => setExpertise(e.target.value)}
          >
            <option value="general">General Knowledge</option>
            <option value="specialized">Specialized Knowledge</option>
          </select>
        </div> */}
      </form>
    );
});

export default CreateAssistantForm;