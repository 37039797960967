import React from "react";
import dynamic from "next/dynamic";
import { ImageProps } from "next/image";

const LazyImage = dynamic(() => import("next/image"), { ssr: false });

const DynamicImage: React.FC<ImageProps> = (props) => {
  return <LazyImage {...props} loading="lazy" />;
};

export default DynamicImage;
